import { FC, useState, useEffect } from "react";
import {
  Button,
  message,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";

import dayjs from "dayjs";
import "./EntityList.css";
import {
  openDoc,
  openQueryWithTimestamp,
  TCustomRouteComponentProps,
} from "utils";
import { Link } from "react-router-dom";

import db from "services/firestore";
import { IEntity, IEntityDetails } from "types";

const getEntities = async () => {
  try {
    const entities = await db
      .collection("entities")
      .where("status", "==", "onboarded")
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created"]));

    return entities.sort((a, b) =>
      b._created.toISOString().localeCompare(a._created.toISOString())
    );
  } catch (error) {
    console.log(error);
  }
};

const getEntityDetails = async (entityId: string, entity: IEntity) => {
  try {
    const entityCompanyDetails = await db
      .collection("entities")
      .doc(entityId)
      .collection("onboarding")
      .doc("companyDetails")
      .get()
      .then((doc) => openDoc(doc) as IEntityDetails);

    return {
      ...entity,
      ddNextDate: entityCompanyDetails?.ddNextDate,
    };
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

type IEntityWithDDNextDate = IEntity & { ddNextDate?: string };

const sortEntitiesByDDNextDate = (
  a: IEntityWithDDNextDate,
  b: IEntityWithDDNextDate
  // @ts-ignore
) => dayjs(a.ddNextDate, "YYYY-MM-DD") - dayjs(b.ddNextDate, "YYYY-MM-DD");

const entityColumns = (isFetchedCompanyDetails: boolean) => [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY"),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record.id}>{text}</Link>
          {!record.enabled && (
            <>
              {" "}
              <Tag color="red">DISABLED</Tag>
            </>
          )}
        </span>
      );
    },
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "CC Account Id",
    dataIndex: ["externalRefs", "ccId"],
    key: "ccId",
  },
  {
    title: "CC User/OnBehalfOf Id",
    dataIndex: ["externalRefs", "ccOnBehalfOfId"],
    key: "ccOnBehalfOfId",
  },
  ...(isFetchedCompanyDetails
    ? [
        {
          title: "DD Next Date",
          dataIndex: "ddNextDate",
          key: "ddNextDate",
          render: (ddNextDate) => {
            if (!ddNextDate) {
              return <span>No DD Next Date</span>;
            }

            return (
              <span>
                {dayjs(ddNextDate, "YYYY-MM-DD").format("DD MMM YYYY")}
              </span>
            );
          },
          sorter: sortEntitiesByDDNextDate,
        },
      ]
    : []),
];

const EntityList: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [isFetchedCompanyDetails, setIsFetchedCompanyDetails] = useState(false);
  const [entities, setEntities] = useState<IEntity[] | IEntityWithDDNextDate[]>(
    []
  );

  console.log(entities);

  useEffect(() => {
    getEntities().then((res) => {
      if (res) {
        setEntities(res);
      }
    });
  }, []);

  const fetchCompanyDetailsForAllEntities = async () => {
    const entitiesToReturn = [];

    const promises = entities.map((entity) =>
      getEntityDetails(entity.id, entity)
    );

    await Promise.all(promises)
      .then((res) => {
        res.forEach((entity) => {
          entitiesToReturn.push(entity);
        });
      })
      .catch((err) => {
        message.error(err);
      });

    setEntities(entitiesToReturn);
    setIsFetchedCompanyDetails(true);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {entities && (
        <Table
          title={() => (
            <Row justify="space-between">
              <Title level={2}>Onboarded Entities</Title>

              <Button onClick={fetchCompanyDetailsForAllEntities}>
                Fetch DD Next Date
              </Button>
            </Row>
          )}
          // @ts-ignore
          columns={entityColumns(isFetchedCompanyDetails)}
          dataSource={entities}
        />
      )}
    </div>
  );
};

export default EntityList;
