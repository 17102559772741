import { FC } from "react";
import { Drawer, Table } from "antd";
import "antd/dist/antd.css";

import { IWalletTransactionWritableResourceWithWriteDetails } from "../interfaces";
import { toCurrencyNumber } from "utils";

const writeColumns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text, record: IWalletTransactionWritableResourceWithWriteDetails) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.amount)}&nbsp;
          <b>{record.currency}</b>
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text, record: IWalletTransactionWritableResourceWithWriteDetails) => {
      let description = record.description || "";
      if (!record.description && record.errorCode) {
        description = record.errorCode;
      };
      return (
        <span>
          {description}
        </span>
      );
    }
  },
  {
    title: "HedgeFlows ID",
    dataIndex: "hedgeFlowsId",
    key: "hedgeFlowsId",
  },
  {
    title: "Write Doc ID",
    dataIndex: "docId",
    key: "docId",
  },
  {
    title: "Event ID",
    dataIndex: "eventId",
    key: "eventId",
  },
];

interface OwnProps {
  records: IWalletTransactionWritableResourceWithWriteDetails[];
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const WritableRecords: FC<OwnProps> = ({
  records,
  isVisible,
  onClose,
}) => {

  return (
    <Drawer
      title={`Writable Records`}
      width={720}
      placement="bottom"
      onClose={() => onClose()}
      open={isVisible}
    >
      <Table
        columns={writeColumns}
        dataSource={records}
        pagination={{pageSize: 3}}
      />

    </Drawer>
  );
};

export default WritableRecords;
